import moment from 'moment'
window.moment = moment
import { marked } from 'marked';
import { mUtil } from '../assets/metro5/assets/demo/default/base/scripts.bundle.fix.js'
import cssVars from 'css-vars-ponyfill'

//== Class definition
var AppEvewa4 = function () {

    var _setValues = function (fields) {
        if (!mUtil.isEmpty(fields)) {
            for (let field in fields) {
                let el = fields[field];
                jQuery(el.selector).val(el.fieldvalue);
            }
        }
    }

    var _setTexts = function (fields) {
        if (!mUtil.isEmpty(fields)) {
            for (var field in fields) {
                let el = fields[field];
                jQuery(el.selector).text(el.fieldvalue);
            }
        }
    }

    var _emptyFields = function (fields) {
        if (!mUtil.isEmpty(fields)) {
            for (let field in fields) {
                let el = fields[field];
                jQuery(el.selector).empty();
            }
        }
    }

    /**
     * Checks if script is loaded
     *
     * @param url
     * @returns {boolean}
     */
    var _isMyScriptLoaded = function (url) {
        if (!url) url = "";
        var scripts = document.getElementsByTagName('script');
        for (var i = scripts.length; i--;) {
            if (scripts[i].src == url) return true;
        }
        return false;
    }

    var _addCssFiles = function (cssFiles) {
        $.each(cssFiles, function (key, file) {
            if ($("link[href='" + file + "']").length == 0) {
                if (!file.match(new RegExp("^" + EVEWA_URL))) {
                    file = EVEWA_URL + "/" + file;
                }
                $('head').append('<link type="text/css" rel="stylesheet" href="' + file + '" />');
            }
        });
    }

    var _addJavascriptFiles = function (javascriptFiles) {
        $.each(javascriptFiles, function (key, file) {
            if ($("script[src='" + file + "']").length == 0) {
                if (!file.match(new RegExp("^" + EVEWA_URL))) {
                    file = EVEWA_URL + "/" + file;
                }
                $('body').append('<script type="text/javascript" src="' + file + '"></script>');
            }
        });
    }

    var _addJavascriptModules = function(javascriptModules)
    {
        for (const javascriptModule in javascriptModules) {
            import(/* @vite-ignore */javascriptModules[javascriptModule]);
        }
    };

    var _addJavascript = function (javascript) {
        var js = '';
        $.each(javascript, function (key, text) {
            js = js + text;
        });
    }

    var _assignJs = function (jsonData) {
        if (jsonData) {
            // dynamically add js file to the DOM
            if (jsonData.jsAjaxCallbackFilePath && jsonData.jsAjaxCallbackFunctionName) {
                // check if script tag already exists if dont exists append the tag
                if (!_isMyScriptLoaded(jsonData.jsAjaxCallbackFilePath)) {
                    console.warn('Dynamic <script> loading is deprecated and might not work with Content-Security-Policy in place.');
                    var s = document.createElement("script");
                    s.type = "text/javascript";
                    s.src = jsonData.jsAjaxCallbackFilePath;
                    $("body").append(s).ready(function () {
                        // create instance of js object and call the method
                        let handlingClass = window[jsonData.jsAjaxCallbackFileName];
                        if (typeof handlingClass !== "undefined") {
                            handlingClass[jsonData.jsAjaxCallbackFunctionName](jsonData);
                        }
                    });
                } else { // if script is already loaded do not append, just execute the function
                    let handlingClass = window[jsonData.jsAjaxCallbackFileName];
                    if (typeof handlingClass !== "undefined") {
                        handlingClass[jsonData.jsAjaxCallbackFunctionName](jsonData);
                    }
                }
            }

            // append additional js if needed, theese are not js files but executable js code wrapped in documentready
            if (jsonData.jsAjaxCallbackAdditionalJs && jsonData.jsAjaxCallbackAdditionalJs !== '') {
                // if this tag already exists remove it and add new

                // ROC: you better not delete existing scripts;
                // its better idea to just insert (append) another

                var additionalJs = "";
                $.each(jsonData.jsAjaxCallbackAdditionalJs, function (i, aj) {
                    additionalJs += aj;
                });

                // the callback we are using might be paired with some unique ID
                let unique_id_definition = "";
                if (jsonData.gsa_lfdnr) {
                    unique_id_definition = "let gsa_lfdnr = '" + jsonData.gsa_lfdnr + "';";
                }

                let table_name = "";
                if (jsonData.table_name) {
                    table_name = "let table_name = '" + jsonData.table_name + "';";
                }

                // if there is some code to be executed
                if (additionalJs.trim().length > 0) {
                    let a = document.createElement("script");
                    a.type = "text/javascript";

                    // assign random ID to the newly created script
                    a.id = 'jsAjaxCallbackAdditionalJs-' + Math.floor(Math.random() * (999999 - 100000)) + 100000;
                    a.innerHTML = '$( document ).ready(function() {\n';
                    a.innerHTML += unique_id_definition + '\n';
                    a.innerHTML += table_name + '\n';
                    a.innerHTML += additionalJs;
                    a.innerHTML += '\n' +
                        '});';

                    $("body").append(a);
                }
            }

            if (jsonData.evCssFiles) {
                _addCssFiles(jsonData.evCssFiles);
            }
            if (jsonData.evJavascriptFiles) {
                _addJavascriptFiles(jsonData.evJavascriptFiles)
            }
            if (jsonData.evJavascriptModules) {
                _addJavascriptModules(jsonData.evJavascriptModules)
            }
            if (jsonData.evJavascriptDocumentReady) {
                _addJavascript(jsonData.evJavascriptDocumentReady)
            }
            if (jsonData.options && jsonData.options.ev4csrf) {
                $('#ev4csrf').val(jsonData.options.ev4csrf);
            }
        }
    };

    var _setCallback = function (functions) {

        if (!mUtil.isEmpty(functions)) {
            for (let key in functions) {
                _assignJs(functions[key]);
            }
        }
    };

    var _mm_load_buttons = function mm_load_buttons(guid) {
        var id = $('.maske_id').val();
        if (id === '') {
            // console.log("id empty");
            return;
        }

        $.ajax({
            type: "POST",
            url: 'evewa4ajax.php',
            dataType: "json",
            data: {
                'file': 'maskenmanager.php',
                'action': 'loadbuttons',
                'id': id
            }
        }).done(function (data) {
            $('#mm_button_save').removeClass("red grey light-grey yellow green blue purple");
            $('#mm_button_save').addClass(data["SAVE_COLOR"]);
            $('#mm_button_save_icon').removeClass();
            $('#mm_button_save_icon').addClass(data["SAVE_ICON"]);
            $('#mm_button_save_text').html(data["SAVE_LABEL"]);
            $('[name="gs_show_save_button"]').prop('checked', data["SAVE"]);

            $('#mm_button_cancel').removeClass("red grey light-grey yellow green blue purple");
            $('#mm_button_cancel').addClass(data["CANCEL_COLOR"]);
            $('#mm_button_cancel_icon').removeClass();
            $('#mm_button_cancel_icon').addClass(data["CANCEL_ICON"]);
            $('#mm_button_cancel_text').html(data["CANCEL_LABEL"]);
            $('[name="gs_show_cancel_button"]').prop('checked', data["CANCEL"]);

            $('#mm_button_delete').removeClass("red grey light-grey yellow green blue purple");
            $('#mm_button_delete').addClass(data["DELETE_COLOR"]);
            $('#mm_button_delete_icon').removeClass();
            $('#mm_button_delete_icon').addClass(data["DELETE_ICON"]);
            $('#mm_button_delete_text').html(data["DELETE_LABEL"]);
            $('[name="gs_show_delete_button"]').prop('checked', data["DELETE"]);
            // $.uniform.update();

            if ($('[name="gs_show_save_button"]').prop('checked')) {
                $('#mm_button_save').show();
            } else {
                $('#mm_button_save').hide();
            }

            if ($('[name="gs_show_cancel_button"]').prop('checked')) {
                $('#mm_button_cancel').show();
            } else {
                $('#mm_button_cancel').hide();
            }

            if ($('[name="gs_show_delete_button"]').prop('checked')) {
                $('#mm_button_delete').show();
            } else {
                $('#mm_button_delete').hide();
            }

            $('#mm_buttons').html(data['BUTTONS']);
        });
    };

    var _markdownPreview = function () {
        var content = $('.md-html-preview');
        if (content.length > 0) {
            $.each(content, function (i, val) {
                let md = $(val).html().trim();
                let html = marked.parse(md);
                if (html) {
                    $(val).html(html);
                }
            });
        }
    }

    var _handleQuickFilters = function (data) {

        if (data) {
            $(document).ready(function() {
                $.ajax({
                    type: 'POST',
                    async: false,
                    url: 'evewa4ajax.php',
                    data: {
                        file: 'schnellfilter.php',
                        refresh: 'true',
                        targetguid: '' + data.targetguid + '',
                        maske: '' + data.maske + '',
                        menunr: '' + data.menunr + '',
                        ebrowse_id: '' + data.ebrowse_id + ''
                    }
                }).done(function (data) {
                    if (data.length) {
                        AppEvewa4.assignJsFile(data);
                    }
                });
            });
        }

        $('.select-quick-filter').on('click',function(e){
            e.stopPropagation();
        });

        var startfilter = mUtil.getURLParam('startfilter');
        if (startfilter !== undefined && startfilter !== null && startfilter !== '') {

            startfilter = startfilter.replace(/ß/g, "ss").replace(/ /g, "_");
            startfilter = startfilter.toLowerCase();

            $(document).ready(function () {
                $(document).ajaxComplete(function() {

                    // clear all filters
                    let button = $('tr.filter_' + startfilter);
                    if (button.hasClass('filter_active')) {
                        return;
                    }

                    button.trigger('click'); // simulate clicking on the actual filter
                    console.log('trigger clic on ' + startfilter);

                    // Remove URl param of startfilter, so the filter is not applied again
                    let url = new URL(window.location.href);
                    url.searchParams.delete('startfilter');
                    let newUrl = url.toString();

                    // Update browser history without reloading the page
                    window.history.pushState({}, '', newUrl);

                    $(document).unbind('ajaxComplete'); // Optional: prevent multiple triggers
                });
            });
        }
    }

    /**
     *
     * @returns {string}
     * @private
     */
    var _ev4id = function() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }

    var _userSettingsDropdown = function (data) {
        // User-dropdown: prevent auto-close on settings click.
        $(".m-dropdown-user-settings .m-switch").click(function (e) {
            e.stopPropagation();
        });

        // User-dropdown: AJAX call on switch.
        $(".m-dropdown-user-settings .m-switch input").change(function () {

            mApp.block(".m-dropdown-user-settings", {}); // block while AJAX is executed
            let target_name = $(this).attr("name");
            let target_checked = $(this).is(":checked");
            let action = "set_user_settings";

            if (target_name === "DESKTOP_COMPACT_VIEW") {
                action = "set_user_desktop_settings";
                target_name = $(this).siblings("[name='DESKTOP_NAME']").val();
            }

            $.ajax({
                type: "POST",
                url: 'evewa4ajax.php?file=desktopSavePosition',
                dataType: 'json',
                data: {
                    action: action,
                    target: target_name,
                    value: target_checked,
                }
            }).done(function (result) {
                if (result.success) {
                    // show the result & action afterwards (UX)
                    mApp.unblock(".m-dropdown-user-settings");
                    globalThis.swal.fire({
                        title: 'Erfolg',
                        html: result.message,
                        icon: 'success'
                    });

                    setTimeout(function () {
                        // auto page refresh
                        window.location.reload();
                    }, 1000);
                } else {
                    globalThis.swal.fire({
                        title: 'Fehler',
                        html: result.message,
                        icon: 'error'
                    });
                    mApp.unblock(".m-dropdown-user-settings");
                }
            });
        });
    }

    var _toggleSidebar = function () {
        // although the Toggle should be initialized by now, it is not accessible
        // we need to reinitialize it to be able ot interact with it
        var asideLeftToggle = new mToggle('m_aside_left_minimize_toggle', {
            target: 'body',
            targetState: 'm-brand--minimize m-aside-left--minimize',
            togglerState: 'm-brand__toggler--active'
        });

        // and close it
        asideLeftToggle.toggleOn();
    }

    return {
        //== Init demos
        init: function () {
            _handleQuickFilters();
            _markdownPreview();
            _userSettingsDropdown();
        },
        do: function (command) {
            let commands = JSON.parse(command);
            _emptyFields(commands.empty);
            _setValues(commands.val);
            _setTexts(commands.text);
            _setCallback(commands.callback);
        },
        ev4id: function() {
            return _ev4id();
        },
        setCallback: function (data) {
            _setCallback(data.callback);
        },
        isMyScriptLoaded: function (url) {
            return _isMyScriptLoaded(url);
        },
        mm_load_buttons: function (guid) {
            return _mm_load_buttons(guid)
        },
        toggle_sidebar: function () {
            return _toggleSidebar();
        },
        /**
         * Based on ajax response this object will append js file to the DOM
         * It will create instance of object defined in response. When js file is appended
         * it will call corresponding method defined in jsonData.
         *
         * ***jsonData object structure***
         * String jsonData['jsExecuteMoreFunctions'] if this is set to true we expect array of functions that will be called
         * String jsonData['jsAjaxCallbackFileName'] name of the JS file to be loaded
         * String jsonData['jsAjaxCallbackFilePath'] path to the JS that needs to be loaded
         * String jsonData['jsAjaxCallbackFunctionName'] name of the method located in loaded JS file that will be executed
         * Array jsonData['jsAjaxCallbackAdditionalJs'] Sometimes we need executable JS code. This array will be processed and appended
         * like script tag in the DOM. Also can be empty. Example jsonData['jsCallbackAdditionalJs'][0] = alert('Test');
         *
         * @author Ivan Haviar
         * @type {{assignJsFile}}
         */
        assignJsFile: function (data, use_json) {
            if (use_json === undefined) {
                use_json = false;
            }

            var jsonData = {};
            if (!use_json) {
                try {
                    jsonData = JSON.parse(data);
                } catch (objError) {
                    if (objError instanceof SyntaxError) {
                        console.error(objError.name);
                    } else {
                        console.error(objError.message);
                    }
                }
            } else {
                jsonData = data;
            }

            if (jsonData.jsExecuteMoreFunctions) {
                $.each(jsonData, function (i, val) {
                    if ($.isPlainObject(val)) {
                        _assignJs(val);
                    }
                });
            } else {
                _assignJs(jsonData);
            }
        },
        showLoader: function () {
            $('body').append('<div id="ajax-loader" class="loader"></div>');
        },
        hideLoader: function () {
            if ($('#ajax-loader')) {
                $('#ajax-loader').remove();
            }
        },
        /**
         * Get parameter from URL
         * @param sParam
         * @returns {*}
         */
        getUrlParameter: function getUrlParameter(sParam) {
            let search_string = window.location.search;
            if (search_string === '') {
                search_string = window.location.href;
            }

            var sPageURL = search_string.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
        },
        getURLParameter: function getURLParameter(paramName) {
            var searchString = window.location.search.substring(1),
                i,
                val,
                params = searchString.split("&");

            for (i = 0; i < params.length; i++) {
                val = params[i].split("=");
                if (val[0] == paramName) {
                    return unescape(val[1]);
                }
            }
            return null;
        },
        /**
         * Get all parameters from URL
         *
         * @returns {Array}
         */
        getUrlParameters: function getUrlParameters() {
            var vars = {}, hash;

            if (window.location.href.indexOf('?') !== -1) {
                var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
                for (var i = 0; i < hashes.length; i++) {
                    hash = hashes[i].split('=');
                    vars[hash[0]] = hash[1];
                }
            }
            return vars

        },
        /**
         * Gets the last part of the url. After the last /
         * @param $url
         * @returns {*|string}
         */
        getLastPartOfURL: function getLastPartOfURL(url) {
            var url = url;
            var urlsplit = url.split("/");
            var lastpart = urlsplit[urlsplit.length - 1];
            if (lastpart === '') {
                lastpart = urlsplit[urlsplit.length - 2];
            }
            return lastpart;
        },
        /**
         * Adds parameters to URL and returns it.
         * @param url
         * @param params
         * @returns {boolean|*}
         */
        addParamsToURL: function addParamsToURL(url, params) {
            if (url.length > 0 && params) {
                var returl = url;
                $.each(params, function (key, value) {
                    if (returl.indexOf("?") >= 0) {
                        returl = returl + "&" + key + "=" + value;
                    } else {
                        returl = returl + "?" + key + "=" + value;
                    }
                });
                return returl;
            } else {
                return false;
            }
        },
        validateEmail: function validateEmail($email) {
            // ROC: replacement of more robust regexp validation, which did not include Unicode characters, like "ü" in grün.
            // Anyway: valid email address does not necessarily be an email address that exists (!)
            // We still need to actually send the email to know its existence.
            var emailReg = /(.+)@(.+){2,}\.(.+){2,}/;
            return emailReg.test($email);
        },

        /**
         * Appends validation error to field
         * also appends data-has-validation-error = 1 so we can check all the fields before submit
         *
         * @param field
         * @param msg
         */
        appendError: function appendError(field, msg) {
            $(field).addClass('error');
            $(field).attr('data-has-validation-error', 1);
            if (msg.length > 0) {
                $(field).after('<label class="error">' + msg + '</label>');
            }
        },
        /**
         * Remove error from field that has validation error
         * also removes data-has-validation-error = 1 (not just sets to 0)
         *
         * @param field
         * @param msg
         */
        removeError: function removeError(field, msg) {
            $(field).removeClass('error');
            $(field).removeAttr('data-has-validation-error');
            $(field).parent().find('label.error, label[id$="-error"]').remove();
        },
        /**
         * Checks if is valid JSON
         *
         * @param str
         * @returns {boolean}
         */
        isJsonString: function isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        /**
         * Deserilizes string from function .serialize()
         *
         * @param field
         * @param msg
         */
        deserialize: function deserialize(data) {
            var form_data = data.split('&');
            var input = {};
            $.each(form_data, function (key, value) {
                var data = value.split('=');
                input[decodeURIComponent(data[0])] = decodeURIComponent(data[1]);
            });

            return input;
        },
        translateMonth: function (monthEnglish) {
            var translated = '';
            switch (monthEnglish) {
                case 'January':
                    translated = 'Januar';
                    break;
                case 'February':
                    translated = 'Februar';
                    break;
                case 'March':
                    translated = 'März';
                    break;
                case 'April':
                    translated = 'April';
                    break;
                case 'May':
                    translated = 'Mai';
                    break;
                case 'Juny':
                    translated = 'Juni';
                    break;
                case 'July':
                    translated = 'Juli';
                    break;
                case 'August':
                    translated = 'August';
                    break;
                case 'September':
                    translated = 'September';
                    break;
                case 'October':
                    translated = 'Oktober';
                    break;
                case 'November':
                    translated = 'November';
                    break;
                case 'December':
                    translated = 'Dezember';
                    break;
                default:
                    break;
            }
            return translated;
        },
        handleDateTimePickers: function (prevStart, prevEnd) {
            localize('DATERANGEPICKER').then(function (__) {
                if (jQuery().datepicker) {
                    $('.date-picker').datepicker({
                        format: 'dd.mm.yyyy',
                        autoclose: true
                    });
                }

                if (jQuery().timepicker) {
                    $('.timepicker-default').timepicker();
                    $('.timepicker-24').timepicker({
                        minuteStep: 1,
                        showSeconds: false,
                        showMeridian: false
                    });
                }

                if (!jQuery().daterangepicker) {
                    return;
                }

                $('.date-range').daterangepicker();

                // Die folgende Definition ist wichtig für den DateRangePicker!
                moment.locale(__('de'), {
                    months: [__('Januar'), __('Februar'), __('März'), __('April'), __('Mai'), __('Juni'), __('Juli'), __('August'), __('September'), __('Oktober'), __('November'), __('Dezember')],
                    monthsShort: [__('Jan'), __('Feb'), __('Mrz'), __('Apr'), __('Mai'), __('Jun'), __('Jul'), __('Aug'), __('Sep'), __('Okt'), __('Nov'), __('Dez')],
                    weekdays: [__('Sonntag'), __('Montag'), __('Dienstag'), __('Mittwoch'), __('Donnerstag'), __('Freitag'), __('Samstag')],
                    weekdaysShort: [__('So'), __('Mo'), __('Di'), __('Mi'), __('Do'), __('Fr'), __('Sa')],
                    longDateFormat: {
                        LT: __("h:mm A"),
                        LTS: __("h:mm:ss A"),
                        L: __("DD.MMYYYY"),
                        l: __("D.M.YYYY"),
                        LL: __("Do MMMM YYYY"),
                        ll: __("D MMM YYYY"),
                        LLL: __("Do MMMM YYYY LT"),
                        lll: __("D MMM YYYY LT"),
                        LLLL: __("dddd, Do MMMM YYYY LT"),
                        llll: __("ddd, D MMM YYYY LT")
                    }
                });

                // Und jetzt die Definition für den eigentlichen DateRangePicker
                var ranges = {};
                ranges[__('Heute')] = [Date.today(), Date.today()];
                ranges[__('Gestern')] = [Date.today().add({days: -1}), Date.today().add({days: -1})];
                ranges[__('Letzte 7 Tage')] = [Date.today().add({days: -6}), Date.today()];
                ranges[__('Letzte 30 Tage')] = [Date.today().add({days: -29}), Date.today()];
                ranges[__('Diesen Monat')] = [Date.today().moveToFirstDayOfMonth(), Date.today().moveToLastDayOfMonth()];
                ranges[__('Letzten Monat')] = [Date.today().moveToFirstDayOfMonth().add({months: -1}), Date.today().moveToFirstDayOfMonth().add({days: -1})];
                ranges[__('Aktuelles Jahr')] = [Date.january().moveToFirstDayOfMonth(), Date.december().moveToLastDayOfMonth()];
                ranges[__('Letztes Jahr')] = [Date.today().add({years: -1}), Date.today()];
                ranges[__('Letztes Kalenderjahr')] = [Date.january().add({years: -1}), Date.december().add({years: -1}).moveToLastDayOfMonth()];

                let dateStart = $('[name="init-date-start"]').val();
                let dateEnd = $('[name="init-date-end"]').val();

                $('#dashboard-report-range').daterangepicker({
                    ranges: ranges,
                    locale: {
                        'format': __('DD.MM.YYYY'),
                        'separator': ' ' + __('bis') + ' ',
                        'applyLabel': __('Aktualisieren'),
                        'cancelLabel': __('Abbrechen'),
                        'fromLabel': __('Von'),
                        'toLabel': __('Bis'),
                        'customRangeLabel': __('Manueller Zeitraum'),
                        'daysOfWeek': [__('So'), __('Mo'), __('Di'), __('Mi'), __('Do'), __('Fr'), __('Sa')],
                        'monthNames': [__('Januar'), __('Februar'), __('März'), __('April'), __('Mai'), __('Juni'), __('Juli'), __('August'), __('September'), __('Oktober'), __('November'), __('Dezember')],
                        'firstDay': __('firstDayOfWeek') === 'firstDayOfWeek' ? 1 : __('firstDayOfWeek')
                    },
                    startDate: new Date(dateStart),
                    endDate: new Date(dateEnd),
                    // minDate: '01.01.1950',
                    // maxDate: Date.today().toString('d.MM.yyyy'),
                    opens: 'left',
                    format: __('DD.MM.YYYY'),
                    showWeekNumbers: true,
                }, function (start, end, label) {
                    if (start.format('DD MMMM YYYY') == end.format('DD MMMM YYYY')) {
                        $('#dashboard-report-range span').html(start.format('DD.') + ' ' + start.format('MMMM') + ' ' + start.format('YYYY'));
                    } else {
                        $('#dashboard-report-range span').html(start.format('DD.') + ' ' + start.format('MMMM') + ' ' + start.format('YYYY') + ' - ' + end.format('DD.') + ' ' + end.format('MMMM') + ' ' + end.format('YYYY'));
                    }
                    var target = $('#dashboard-report-range').data("dashboardname");
                    var view = $('#dashboard-report-range').data("dashboardview");
                    //send ajax in order to retrieve stats as JSON
                    var loader = $('#dashboardmainstat1 .m-portlet');
                    mApp.block(loader, {
                        overlayColor: '#000000',
                        type: 'loader',
                        state: 'success',
                    });
                    var url = EVEWA_URL + "/evewa4/evewa4ajax.php?file=ev4dashboardstat&name=" + target + "&view=" + view + "&start=" + start.format('YYYY-MM-DD') + "&ende=" + end.format('YYYY-MM-DD') + "&width=" + $(window).width();
                    var id = AppEvewa4.getUrlParameter('id');
                    if (id) {
                        url = url + '&id=' + id;
                    }

                    $.ajax({
                        url: url,
                        dataType: 'html',
                        type: "POST",
                        complete: function () {
                            mApp.unblock(loader);
                        },
                        error: function (reqObj, errorString, exception) {
                            alert(errorString);
                        }
                    })
                        .done(function (data) {
                            if (data) {
                                AppEvewa4.replaceDashboardStats(data);
                            }
                        });
                });

                $('.daterangepicker .cancelBtn').hide();

                if (!jQuery().datepicker || !jQuery().timepicker) {
                    return;
                }
            });
        },
        replaceDashboardStats: function (data) {
            var idToReplace = '';
            var valueToReplace = '';
            var jsonData = JSON.parse(data);
            $.each(jsonData, function (i, val) {
                idToReplace = 'dashboard_stat_' + val.MGL_E_STATGSA_LFDNR;
                valueToReplace = val.CONTENT1;
                $('#' + idToReplace).html(valueToReplace);
            });
        },
        /**
         * Convert a base64 string in a Blob according to the data and contentType.
         *
         * @param b64Data {String} Pure base64 string without contentType
         * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
         * @param sliceSize {Int} SliceSize to process the byteCharacters
         * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
         * @return Blob
         */
        b64toBlob: function (b64Data, contentType, sliceSize) {
            contentType = contentType || '';
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, {type: contentType});
            return blob;
        },
        onChange: function (data) {
            $('textarea[data-onchange-skript],select[data-onchange-skript],input[data-onchange-skript]').on('change', function () {
                var selected_element = $("#" + this.id);
                var url = selected_element.data("onchangeSkript");
                var table_name = selected_element.data("onchangeTableName");
                var field_name = selected_element.data("onchangeFieldName");
                var formdata = selected_element.closest("form").serializeArray();

                data = {
                    formdata: selected_element.closest("form").serialize(),
                    field_value: selected_element.val(),
                    field_id: this.id,
                    field_name: field_name,
                    current_table: table_name,
                };

                if (selected_element.attr('type') === 'checkbox') {
                    if (selected_element.is(":checked")) {
                        // it is checked
                        data.field_value = 1;
                    } else {
                        data.field_value = 0;
                    }
                }

                for (var i = 0; i < formdata.length; ++i) {
                    var curr = formdata[i];
                    data[curr.name] = curr.value;
                }

                $.ajax({
                    url: "'.EVEWA_URL.'/evewa4/evewa4ajax.php?file=" + url,
                    dataType: "json",
                    async: false,
                    data: data,
                    type: "POST"
                })
                    .done(function (data) {
                        if (data !== null) {
                            if (data.code !== undefined && data.code !== "" && data.useOnchangeResponseHandler === undefined)
                            {
                                console.warn('Warning: Usage of eval() in onchange event detected. This way of handling on change events' +
                                    ' is deprecated, onchange_response_handler should be used instead.');

                                // TODO: this should be removed altogether in future, as any action should be reworked to JSON instructions set
                                eval(data.code);
                            }
                            else if (data.useOnchangeResponseHandler === true && data.code !== undefined && data.code !== "" && data.code !== null)
                            {
                                let json = JSON.parse(data.code);
                                if (json.length > 0) {
                                    $.each(json, function (i, val) {
                                        AppEvewa4.assignJsFile(val, true);
                                    });
                                }

                                // output sweetalerts, if needed
                                if (data.status === "error") {
                                    // "error" status always returns the message
                                    if (data.msg !== undefined && data.msg.trim() !== "") {
                                        globalThis.swal.fire({
                                            title: "Fehler",
                                            html: data.msg,
                                            icon: "error",
                                        });
                                    }
                                    else
                                    {
                                        globalThis.swal.fire({
                                            title: "Fehler",
                                            html: "Es ist ein unbekannter Fehler aufgetreten",
                                            icon: "error",
                                        });
                                    }
                                }
                                else
                                {
                                    // otherwise, return messagebox only when some text is provided
                                    if (data.msg.length > 0) {

                                        let title = ''; // set the title if defined
                                        if (data.hasOwnProperty('title')) {
                                            title = data.title;
                                        }

                                        globalThis.swal.fire({
                                            title: title,
                                            icon: data.status, // 'success' / 'error' / 'warning' / 'info'
                                            html: data.msg,
                                        });
                                    }
                                }
                            }
                        }
                    })
                    .fail(function (jqxhr, textstatus) {
                        globalThis.swal.fire({
                            title: "Anfrage fehlgeschlagen",
                            html: textstatus,
                            icon: "error",
                        });
                    });
            });
        },
        refreshQuickFilters: function (element) {
            if (element.hasClass('filter_active')) {
                element.find('input').prop('checked', true);
            } else {
                element.find('input').prop('checked', false);
            }

            // each time the item is refreshed, we must refresh the general quick-filter status
            $(".quick-filters").removeClass('filtering-active');
            $(".quick-filters .m-ev-quickfilters tr").each(function () {
                if ($(this).hasClass("filter_active")) {
                    $(".quick-filters").addClass('filtering-active');
                    return false;
                }
            });
        },
        handleQuickFilters: function (data) {
            _handleQuickFilters(data);
        },
        /**
         * Function formats / pretty-prints incoming XML string
         *
         * @param xml
         * @returns {string}
         */
        formatXml: function (xml) {
            var formatted = '';
            var reg = /(>)(<)(\/*)/g;
            xml = xml.replace(reg, '$1\r\n$2$3');
            var pad = 0;
            jQuery.each(xml.split('\r\n'), function (index, node) {
                var indent = 0;
                if (node.match(/.+<\/\w[^>]*>$/)) {
                    indent = 0;
                } else if (node.match(/^<\/\w/)) {
                    if (pad != 0) {
                        pad -= 1;
                    }
                } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
                    indent = 1;
                } else {
                    indent = 0;
                }

                var padding = '';
                for (var i = 0; i < pad; i++) {
                    padding += '  ';
                }

                formatted += padding + node + '\r\n';
                pad += indent;
            });
            return formatted;
        },
        getSwitchGliederungScript: function () {
            $.ajax({
                type: "GET",
                url: "evewa4ajax.php",
                data:
                    {
                        file: "gliederungChange",
                        action: "generate-script"
                    },
                dataType: "json"
            }).done(function (response) {
                AppEvewa4.assignJsFile(response, false);
            });
        },
        getSelectGliederungScript: function (source) {
            $.ajax({
                type: "GET",
                url: "evewa4ajax.php",
                data:
                    {
                        file: "gliederungSelect",
                        action: "generate-script",
                    },
                dataType: "json"
            }).done(function (response) {
                AppEvewa4.assignJsFile(response, false);
            });
        },
        getSwitchMandantScript: function () {
            $.ajax({
                type: "GET",
                url: "evewa4ajax.php",
                data:
                    {
                        file: "mandantenGSChange",
                        action: "generate-script"
                    },
                dataType: "json"
            }).done(function (response) {
                AppEvewa4.assignJsFile(response, false);
            });
        },
        getSelectMandantScript: function (source) {
            $.ajax({
                type: "GET",
                url: "evewa4ajax.php",
                data:
                    {
                        file: "mandantSelect",
                        action: "generate-script",
                    },
                dataType: "json"
            }).done(function (response) {
                AppEvewa4.assignJsFile(response, false);
            });
        },
        getSwitchPeriodScript: function () {
            $.ajax({
                type: "GET",
                url: "evewa4ajax.php",
                data:
                    {
                        file: "periodChange",
                        action: "generate-script"
                    },
                dataType: "json"
            }).done(function (response) {
                AppEvewa4.assignJsFile(response, false);
            });
        },
        /**
         * Shows text on password field
         * @param input_selector selector of main input field
         * @param span_selector selector of icon to show password
         *    - parameter is not required
         * @private
         */
        show_password : function show_password(input_selector,span_selector='') {
            let input_object = $(input_selector);
            let spanSelector = span_selector === '' ? input_selector + '-show-password' : span_selector;
            let show_object = $(spanSelector);
            let icon_object = $(spanSelector + " i");

            show_object.on('click', function (e) {
                if (input_object[0].type === "password") {
                    input_object[0].type = "text";
                    icon_object.removeClass("fa-eye");
                    icon_object.addClass("fa-eye-slash");
                    show_object.attr("data-original-title","Passwort verbergen");
                } else {
                    input_object[0].type = "password";
                    icon_object.removeClass("fa-eye-slash");
                    icon_object.addClass("fa-eye");
                    show_object.attr("data-original-title","Passwort anzeigen");
                }
            });
        }
    };
}();

var ev3blockUi = function () {
    return {
        ev3blockUI: function (el, centerY) {
            jQuery(el).block({
                message: '<span class="ajax-loading">&nbsp;</span>',
                centerY: centerY != undefined ? centerY : true,
                css: {
                    top: '40%',
                    left: '50%',
                    width: '100%',
                    border: 'none',
                    padding: '2px',
                    backgroundColor: 'none'
                },
                overlayCSS: {
                    backgroundColor: '#000',
                    opacity: 0.1,
                    cursor: 'wait'
                }
            });
        },
        // wrapper function to  un-block element(finish loading)
        ev3unblockUI: function (el) {
            jQuery(el).unblock();
        },

        ev3globalBlock: function () {
            $('#meatshield').show();
        },
        ev3globalUnblock: function () {
            $('#meatshield').hide();
        }
    };
}();

$(document).ready(function () {
    AppEvewa4.init();

    //STOP PROPAGATION CLASS - avoid usage of inline js
    $('.stop-propagation').on('click', function (e) {
        e.stopPropagation();
    });

    /*Menu hotfix*/
    var menuel;
    menuel = $('.m-menu__item--active').parents('.m-menu__item');
    if (menuel.length > 0) {
        menuel.addClass('m-menu__item--open');
    }
    /*IE 11 css variables*/
    // Default values
    cssVars({
        // Targets
        rootElement: document,
        shadowDOM: false,

        // Sources
        include: 'link[rel=stylesheet],style',
        exclude: '',
        variables: {},

        // Options
        onlyLegacy: true,
        preserveStatic: true,
        preserveVars: false,
        silent: false,
        updateDOM: true,
        updateURLs: true,
        watch: false,

        // // Callbacks
        // onBeforeSend(xhr, elm, url) {
        //     // ...
        // },
        // onWarning(message) {
        //     // ...
        // },
        // onError(message, elm, xhr, url) {
        //     // ...
        // },
        // onSuccess(cssText, elm, url) {
        //     // ...
        // },
        // onComplete(cssText, styleElms, cssVariables, benchmark) {
        //
        // }
    });
});

export { AppEvewa4, ev3blockUi };